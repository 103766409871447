import { Injectable } from "@angular/core";
import { GeService } from "@app/core/services/ge.service";
import { GeGroupActions } from "@app/store/ge-groups/ge-groups-action-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";

@Injectable()
export class GeGroupsEffects {

  constructor(private actions$: Actions,
              private geService: GeService) {
  }

  //#region READ/LOAD
  loadGeGroupsForTermCode$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(GeGroupActions.loadGeGroupsByTermCode),
        map(action => action),
        mergeMap(action => this.geService.getByTermCode(action.termCode).pipe(
            map(geGroups => {
                return GeGroupActions.loadGeGroupsByTermCodeSuccess({geGroups, termCode: action.termCode});
              },
            ),
            catchError(error => {
              return of(GeGroupActions.loadGeGroupsByTermCodeFailure({
                error: error
              }))
            })
          )
        )));
  //#endregion
}
