import { IGeCategoryDetailsVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from '@models/api-response-error.model';
import { createAction, props } from "@ngrx/store";

export const loadGeCategoryDetails = createAction(
  "[GeCategory Details Actions] Load GeCategory Details For GeCategory",
  props<{ termCode: number, geRequirementPrefix: string, geCategoryPrefix: string }>()
);

export const loadGeCategoryDetailsSuccess = createAction(
  "[GeCategory Details Actions] GeCategoryDetails Successfully Loaded For GeCategory",
  props<{ geCategoryDetails: IGeCategoryDetailsVM, concatenatedKey: string }>()
);

export const loadGeCategoryDetailsFailure = createAction(
  "[GeCategory Details Effect] Load GeCategoryDetails Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const clearError = createAction(
  "[GeCategory Details Actions] Clear GeCategoryDetails Error"
);
