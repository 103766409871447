import { Injectable } from "@angular/core";
import { CoursesService } from '@app/core/services/courses.service';
import { CourseActions } from '@app/store/courses/courses-action-types';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";

@Injectable()
export class CoursesEffects {

  constructor(private actions$: Actions,
              private coursesService: CoursesService) {
  }

  //#region READ/LOAD
  loadCourse$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(CourseActions.loadCourse),
        map(action => action),
        mergeMap(action => this.coursesService.getCourse(action.termCode, action.courseCode).pipe(
          map(course => {
              return CourseActions.loadCourseSuccess({
                course,
                concatenatedKey: course.termCode + course.matchedCourseCode?.prefix! + course.matchedCourseCode?.number + (course.sequence ? course.sequence : '')
              });
            },
          ),
          catchError(error => {
            return of(CourseActions.loadCourseFailure({
              error: error
            }))
          }),
        )))
  );
  //#endregion
}
