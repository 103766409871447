import { Injectable } from "@angular/core";
import { COURSES, ICourseModel } from "@app/core/mocks/course.model";
import {
  ConfigurationCourseDefinition,
  IConfigurationCourseDefinition,
  ICourseVM,
  IGeCategoryCoursesVM,
  IProgramCoursesVM
} from '@app/core/services/WebRegApiClient';
import {
  FilterDefault,
  IFilterOptions
} from "@modules/main/term/components/search/filter-dialog/filter-dialog.component";
import { map, Observable, of } from "rxjs";
import { WebRegService } from "./api-request.service";
import { getMinutesFromTime24 } from "./dateTime.service";


@Injectable()
export class CoursesService {

  constructor(private apiService: WebRegService) {
  }

  getCourse(termCode: number, courseCode: string): Observable<ICourseVM> {
    return this.apiService.apiCoursesCourse(termCode, courseCode)
      .pipe(map(r => r.toJSON() as ICourseVM)
      );
  }

  getCourses(termCode: number, schoolCode: string, programCode: string): Observable<IProgramCoursesVM> {
    return this.apiService.apiCoursesCoursesByTermSchoolProgram(termCode, schoolCode, programCode)
      .pipe(map(r => r.toJSON() as IProgramCoursesVM)
      );
  }

  getGeCourses(termCode: number, geRequirementPrefix: string, geCategoryPrefix: string): Observable<IGeCategoryCoursesVM> {
    return this.apiService.apiCoursesGeCoursesByTerm(termCode, geRequirementPrefix, geCategoryPrefix)
      .pipe(map(r => r.toJSON() as IGeCategoryCoursesVM)
      );
  }

  getCoursesByPreviewConfig(programConfig: IConfigurationCourseDefinition): Observable<IProgramCoursesVM> {
    return this.apiService.apiCoursesCoursesByPreviewConfig(programConfig as ConfigurationCourseDefinition)
      .pipe(map(r => r.toJSON() as IProgramCoursesVM)
      );
  }

  getCoursesHavePrefixInTerm(prefix: string, term: number): Observable<boolean> {
    return this.apiService.apiPeCoursesHavePrefixInTerm(prefix, term)
      .pipe(map(resp => {
        return resp;
      }));
  }

  getDoesCourseExist(courseCode: string): Observable<boolean> {
    return this.apiService.apiPeDoesCourseExist(courseCode)
      .pipe(map(resp => {
        return resp;
      }));
  }

  //** Reactive UI Prototype Remnants - Will Probably jus be removed as replaced.  */
  getByCourseId(id: number) {
    return of(COURSES.filter(c => c.courseId === id))
  }

  getBySchoolPrefixDepartmentPrefix(termCode: string | undefined,
                                    schoolPrefix: string | undefined,
                                    departmentPrefix: string | undefined,
                                    ignoreCase: boolean = true): Observable<IProgramCoursesVM[]> {

    return of((COURSES.filter(x => {
      if (x.termCode?.toString() !== termCode
        || !departmentPrefix
        || !x.levelTwoPrefix) {
        return;
      }

      const levelTwoPrefix = ignoreCase ? x.levelTwoPrefix.toLowerCase() : x.levelOnePrefix;
      departmentPrefix = ignoreCase ? departmentPrefix.toLowerCase() : departmentPrefix;

      if (levelTwoPrefix?.includes(departmentPrefix)) {
        return x;
      }
      return;
    }).map(r => r).sort((a, b) => (parseInt(<string>a.classNumber) - parseInt(<string>b.classNumber)))));
  }

  searchQuick(termCode: number | undefined,
              searchTerm: string,
              ignoreCase: boolean = true,
              filters: IFilterOptions = FilterDefault): Observable<ICourseModel[]> {

    const termCourses = COURSES.filter(c => {
      if (!c.termCode || c.termCode !== termCode) {
        return;
      }

      termCode = termCode || 0;

      const title = (ignoreCase ? c.courseTitle?.toLowerCase() : c.courseTitle) || '';
      searchTerm = (ignoreCase ? searchTerm.toLowerCase() : searchTerm) || '';
      let sectionNames = c.sections?.flatMap(s => ignoreCase ? s.name?.toLowerCase() : s.name);
      const combinedId = `${ c.prefix } ${ c.classNumber }${ c.sequence }`;
      const courseId = (ignoreCase ? combinedId?.toLowerCase() : combinedId) || '';

      if (title.includes(searchTerm)
        || sectionNames?.some(s => s?.includes(searchTerm))
        || courseId.includes(searchTerm)
      ) {

        return c;
      }

      return;
    });

    const filtered = this.applyFilters(termCourses, filters);
    return of(filtered);
  }

  searchByTitlePrefix(termCode: number | undefined,
                      searchTerm: string,
                      ignoreCase: boolean = true,
                      filters: IFilterOptions = FilterDefault): ICourseModel[] {
    const courses = COURSES.filter(c => {
      if (!c.termCode || c.termCode !== termCode) {
        return;
      }

      let prefix = c.prefix || '';
      let title = c.courseTitle || '';
      searchTerm = searchTerm || '';
      termCode = termCode || 0;

      prefix = ignoreCase ? prefix.toLowerCase() : prefix;
      title = ignoreCase ? title.toLowerCase() : title;
      searchTerm = ignoreCase ? searchTerm.toLowerCase() : searchTerm;

      if (prefix.includes(searchTerm)
        || title.includes(searchTerm)) {
        return c;
      }

      return;
    });

    const filtered = this.applyFilters(courses, filters);
    return filtered;
  }

  searchByTitlePrefixDescription(termCode: number | undefined,
                                 searchTerm: string,
                                 ignoreCase: boolean = true,
                                 filters: IFilterOptions = FilterDefault): ICourseModel[] {
    const courses = COURSES.filter(c => {
      if (!c.termCode || c.termCode !== termCode) {
        return;
      }

      let prefix = c.prefix || '';
      let title = c.courseTitle || '';
      let description = c.description || '';
      searchTerm = searchTerm || '';
      termCode = termCode || 0;

      prefix = ignoreCase ? prefix.toLowerCase() : prefix;
      title = ignoreCase ? title.toLowerCase() : title;
      description = ignoreCase ? description.toLowerCase() : description;
      searchTerm = ignoreCase ? searchTerm.toLowerCase() : searchTerm;

      if (prefix.includes(searchTerm)
        || title.includes(searchTerm)
        || description.includes(searchTerm)) {
        return c;
      }

      return;
    });

    const filtered = this.applyFilters(courses, filters);
    return filtered;
  }

  searchByAll(termCode: number | undefined,
              searchTerm: string,
              ignoreCase: boolean = true,
              filters: IFilterOptions = FilterDefault): ICourseModel[] {

    searchTerm = (ignoreCase ? searchTerm?.toLowerCase() : searchTerm) || '';

    const courses = COURSES.filter(c => {
      if (!c.termCode || c.termCode !== termCode) {
        return;
      }

      const prefix = (ignoreCase ? c.prefix?.toLowerCase() : c.prefix) || '';
      const title = (ignoreCase ? c.courseTitle?.toLowerCase() : c.courseTitle) || '';
      const description = (ignoreCase ? c.description?.toLowerCase() : c.description) || '';
      const courseId = (ignoreCase ? c.courseId?.toString().toLowerCase() : c.courseId?.toString()) || '';
      const classNumber = (ignoreCase ? c.classNumber?.toLowerCase() : c.classNumber) || '';
      const courseNotes = (ignoreCase ? c.courseNotes?.toLowerCase() : c.courseNotes) || '';

      const combinedId = `${ c.prefix } ${ c.classNumber }${ c.sequence }`;
      const catalogNumber = (ignoreCase ? combinedId?.toLowerCase() : combinedId) || '';

      if (prefix.includes(searchTerm)
        || title.includes(searchTerm)
        || description.includes(searchTerm)
        || courseId.includes(searchTerm)
        || classNumber.includes(searchTerm)
        || courseNotes.includes(searchTerm)
        || catalogNumber.includes(searchTerm)) {

        return c;
      }

      return;
    });

    return this.applyFilters(courses, filters);
  }

  search(termCode: string | undefined,
         searchValue: string,
         ignoreCase: boolean = true,
         filters: IFilterOptions = FilterDefault): Observable<ICourseModel[]> {
    const courses = COURSES.filter(c => {
      if (c.termCode?.toString() !== termCode
        || !c.courseTitle
        || !c.prefix) {
        return;
      }

      const prefix: string = ignoreCase ? c.prefix.toLowerCase() : c.prefix;
      const courseTitle: string = ignoreCase ? c.courseTitle.toLowerCase() : c.courseTitle;

      if (courseTitle.includes(searchValue) || prefix.includes(searchValue)) {
        return c;
      }

      return;
    });

    const filterd = this.applyFilters(courses, filters);
    return of(filterd);
  }

  private applyFilters(courses: ICourseModel[], filters: IFilterOptions = FilterDefault): ICourseModel[] {
    const requiredDayCodes = (filters?.days.length === 1 && filters.days[0] === 'Any')
      ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      : filters!.days;

    return courses.filter((course) =>
        (filters.units === 0 || filters.units === course.units)
        && course.sections?.some((section) =>
          section.locations?.some((location) =>
            location.dayCodes?.some((day) => requiredDayCodes.includes(day))
            && (location?.startTime && getMinutesFromTime24(location?.startTime) >= getMinutesFromTime24(filters!.timeRangeMin))
            && (location?.endTime && getMinutesFromTime24(location.endTime) <= getMinutesFromTime24(filters!.timeRangeMax))
          )
        )
    );
  }
}
