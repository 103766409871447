import { APP_BASE_HREF, CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppConfig } from "@app/app.config";
import { Permissions } from "@app/core/guards/can-deactivate-admin-form";
import { PhoneFormatPipe } from "@app/core/pipes/phone-format.pipe";
import { AlertService } from "@app/core/services/alert.service";
import { AnnouncementService } from '@app/core/services/announcement.service';
import { LoggerService } from "@app/core/services/logger.service";
import { PeService } from "@app/core/services/pe.service";
import { ProgramsService } from "@app/core/services/programs.service";
import { TermsService } from "@app/core/services/terms.service";
import { ToastNotificationService } from "@app/core/services/toast-notification.service";
import { API_BASE_URL } from "@app/core/services/WebRegApiClient";
import { metaReducers, reducers } from "@app/store/app.reducer";
import { CoursesEffects } from '@app/store/courses/courses.effects';
import { GeGroupsEffects } from '@app/store/ge-groups/ge-groups.effects';
import { ProgramCourseListEffects } from "@app/store/program-course-list/program-course-list.effects";
import { ProgramsEffects } from "@app/store/programs/programs.effects";
import { SchoolsEffects } from "@app/store/schools/schools.effects";
import { SessionsEffects } from "@app/store/sessions/sessions.effects";
import { TermsEffects } from "@modules/main/homepage/store/terms/terms.effects";
import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomToastComponent } from "@shared-components/custom-toast/custom-toast.component";
import { SharedComponentsModule } from "@shared-components/shared-components.module";
import { provideToastr, ToastrService } from "ngx-toastr";
import { environment } from "../environments/environment";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoursesService } from "./core/services/courses.service";
import { DepartmentsService } from "./core/services/departments.service";
import { SchoolsService } from "./core/services/schools.service";
import { CsrfHeaderInterceptor } from "./csrf-header.interceptor";
import { entityConfig } from './store/entity-metadata';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    AppComponent,
    PhoneFormatPipe
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    EffectsModule.forRoot([
      TermsEffects,
      SchoolsEffects,
      ProgramsEffects,
      SessionsEffects,
      ProgramCourseListEffects,
      CoursesEffects,
      GeGroupsEffects
    ]),
    StoreRouterConnectingModule.forRoot({
      stateKey: "router",
      routerState: RouterState.Minimal
    }),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),
    EntityDataModule.forRoot(entityConfig),
  ],
  providers: [
    {provide: API_BASE_URL, useValue: environment.apiBaseUrl},
    {provide: HTTP_INTERCEPTORS, useClass: CsrfHeaderInterceptor, multi: true},
    {provide: APP_BASE_HREF, useValue: environment.apiBaseHref},
    AppConfig,
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true},
    TermsService,
    SchoolsService,
    CoursesService,
    AnnouncementService,
    DepartmentsService,
    AlertService,
    ToastNotificationService,
    provideToastr({toastComponent: CustomToastComponent, positionClass: 'toast-bottom-right'}),
    ToastrService,
    MatSnackBar,
    ProgramsService,
    Permissions,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    },
    PeService,
    LoggerService,
    PhoneFormatPipe,
    CoursesService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
