import { IGeCategoryCoursesVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";

export const loadGeCategoryCourseList = createAction(
  "[GeCategory Course List Actions] Load GeCategoryCourseList",
  props<{ termCode: number, geRequirementPrefix: string, geCategoryPrefix: string }>()
);

export const loadGeCategoryCourseListSuccess = createAction(
  "[GeCategory Course List Effect] GeCategoryCourseList Successfully Loaded",
  props<{ geCategoryCourseList: IGeCategoryCoursesVM, concatenatedKey: string }>()
);

export const loadGeCategoryCourseListFailure = createAction(
  "[GeCategory Course List Effect] Load GeCategoryCourseList Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const clearError = createAction(
  "[GeCategory Course List Actions] Clear GeCategoryCourseList Error"
);
