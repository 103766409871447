import { IGeVM } from "@app/core/services/WebRegApiClient";
import { GeGroupActions } from "@app/store/ge-groups/ge-groups-action-types";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface GeGroupsState extends EntityState<IGeVM> {
  areGeGroupsLoadedForTerm: Record<number, boolean>;
  error: ApiResponseErrorModel | undefined;
}

export function selectId(a: IGeVM): number {
  return <number>a.termCode;
}

export const areGeGroupsLoadedForTerm = (state: GeGroupsState, termCode: number) => state.areGeGroupsLoadedForTerm[termCode];

export const adapter = createEntityAdapter<IGeVM>(
  {
    selectId: selectId
  }
);

export const initialGeGroupsState = adapter.getInitialState({
  areGeGroupsLoaded: false,
  areGeGroupsLoadedForTerm: {}
});

export const getErrorMessage = (state: GeGroupsState) => state.error;

export const geGroupsReducer = createReducer(
  initialGeGroupsState,

  on(GeGroupActions.loadGeGroupsByTermCodeSuccess, (state, action) =>
    adapter.addOne(action.geGroups, {
      ...state, areGeGroupsLoadedForTerm: {
        ...state.areGeGroupsLoadedForTerm,
        [action.termCode]: true
      }
    })),

  on(GeGroupActions.loadGeGroupsByTermCodeFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(GeGroupActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
