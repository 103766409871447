import { IGeCategoryCoursesVM } from "@app/core/services/WebRegApiClient";
import { GeCategoryCourseListActions } from '@app/store/ge-category-course-list/ge-category-course-list-action-types';
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface GeCategoryCourseListState extends EntityState<IGeCategoryCoursesVM> {
  areGeCategoryCourseListLoadedForTermSchoolGeCategory: Record<string, boolean>;
  error: ApiResponseErrorModel | string | undefined;
}

export function selectId(a: IGeCategoryCoursesVM): string {
  return <number>a.termCode + a.geRequirementPrefix?.toString()! + <string>a.geCategoryPrefix;
}

export const areGeCategoryCourseListLoadedForTermSchoolGeCategory = (state: GeCategoryCourseListState, concatenatedKey: string) => state.areGeCategoryCourseListLoadedForTermSchoolGeCategory[concatenatedKey];

export const adapter = createEntityAdapter<IGeCategoryCoursesVM>(
  {
    selectId: selectId
  }
);

export const initialGeCategoryCourseListState = adapter.getInitialState({
  areGeCategoryCourseListLoadedForTermSchoolGeCategory: {}
});

export const getErrorMessage = (state: GeCategoryCourseListState) => state.error;

export const geCategoryCourseListReducer = createReducer(
  initialGeCategoryCourseListState,

  on(GeCategoryCourseListActions.loadGeCategoryCourseListSuccess, (state, action) =>
    adapter.addOne(action.geCategoryCourseList, {
      ...state, areGeCategoryCourseListLoadedForTermSchoolGeCategory: {
        ...state.areGeCategoryCourseListLoadedForTermSchoolGeCategory,
        [action.concatenatedKey]: true
      }
    })
  ),

  on(GeCategoryCourseListActions.loadGeCategoryCourseListFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(GeCategoryCourseListActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
