import { Injectable } from "@angular/core";
import { WebRegService } from "@app/core/services/api-request.service";
import { map, Observable } from "rxjs";
import { GeVM, IGeCategoryDetailsVM } from "./WebRegApiClient";


@Injectable({
  providedIn: "root",
})
export class GeService {

  constructor(private apiService: WebRegService) {
  }

  getByTermCode(termCode: number | null): Observable<GeVM> {
    return this.apiService.apiGeTermCode(termCode || 0)
      .pipe(map(resp => resp.toJSON() as GeVM));
  }

  getCategoryDetails(termCode: number, geRequirementPrefix: string, geCategoryPrefix: string): Observable<IGeCategoryDetailsVM> {
    return this.apiService.apiProgramsGeCategoryDetails(termCode, geRequirementPrefix, geCategoryPrefix)
      .pipe(map(r => r.toJSON() as IGeCategoryDetailsVM)
      );
  }
}
