import { IGeCategoryDetailsVM } from "@app/core/services/WebRegApiClient";
import { GeCategoryDetailsActions } from '@app/store/ge-category-details/ge-category-details-action-types';
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface GeCategoryDetailsState extends EntityState<IGeCategoryDetailsVM> {
  areGeCategoryDetailsLoadedForGeCategory: Record<string, boolean>;
  error: ApiResponseErrorModel | undefined;
}

export function selectId(a: IGeCategoryDetailsVM): string {
  return <number>a.termCode + a.geRequirementPrefix!.toString() + <string>a.prefix;
}

export const areGeCategoryDetailsLoadedForGeCategory = (state: GeCategoryDetailsState, concatenatedKey: string) => state.areGeCategoryDetailsLoadedForGeCategory[concatenatedKey];

export const adapter = createEntityAdapter<IGeCategoryDetailsVM>(
  {
    selectId: selectId
  }
);

export const initialGeCategoryDetailsState = adapter.getInitialState({
  areGeCategoryDetailsLoadedForGeCategory: {}
});

export const getErrorMessage = (state: GeCategoryDetailsState) => state.error;

export const geCategoryDetailsReducer = createReducer(
  initialGeCategoryDetailsState,

  on(GeCategoryDetailsActions.loadGeCategoryDetailsSuccess, (state, action) =>
    adapter.addOne(action.geCategoryDetails, {
      ...state, areGeCategoryDetailsLoadedForGeCategory: {
        ...state.areGeCategoryDetailsLoadedForGeCategory,
        [action.concatenatedKey]: true
      }
    })),

  on(GeCategoryDetailsActions.loadGeCategoryDetailsFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(GeCategoryDetailsActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
