import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { ISearchResultsVm } from "@app/core/services/WebRegApiClient";
import { SearchActions } from "@app/store/search/search-action-types";

export interface SearchState extends EntityState<ISearchResultsVm> {
  areSearchLoadedForTerm: Record<number, boolean>;
  error: ApiResponseErrorModel | undefined;
}

export function selectId(a: ISearchResultsVm): number {
  return <number>a.termCode;
}

export const areSearchLoadedForTerm = (state: SearchState, termCode: number) => state.areSearchLoadedForTerm[termCode];

export const adapter = createEntityAdapter<ISearchResultsVm>(
  {
    selectId: selectId
  }
);

export const initialSearchState = adapter.getInitialState({
  areSearchLoaded: false,
  areSearchLoadedForTerm: {}
});

export const getErrorMessage = (state: SearchState) => state.error;

export const searchReducer = createReducer(
  initialSearchState,

  on(SearchActions.preloadSearchByTermCodeSuccess, (state, action) =>
    adapter.addOne(action.search, {
      ...state, areSearchLoadedForTerm: {
        ...state.areSearchLoadedForTerm,
        [action.termCode]: true
      }
    })),

  on(SearchActions.preloadSearchByTermCodeFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(SearchActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
