import { ICourseVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";

export const loadCourse = createAction(
  "[Course Actions] Load Course",
  props<{ termCode: number, courseCode: string }>()
);

export const loadCourseSuccess = createAction(
  "[Course Effect] Course Successfully Loaded",
  props<{ course: ICourseVM, concatenatedKey: string }>()
);

export const loadCourseFailure = createAction(
  "[Course Effect] Load Course Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const clearError = createAction(
  "[Course Actions] Clear Course Error"
);
