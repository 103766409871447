import { IGeVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";

export const loadGeGroupsByTermCode = createAction(
  "[GeGroup Actions] Load GeGroups By Term Code",
  props<{ termCode: number }>()
);

export const loadGeGroupsByTermCodeFailure = createAction(
  "[GeGroup Effect] Load GeGroups By Term Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const loadGeGroupsByTermCodeSuccess = createAction(
  "[GeGroup Actions] GeGroups Successfully Loaded For Term",
  props<{ geGroups: IGeVM, termCode: number }>()
);

export const clearError = createAction(
  "[GeGroups Actions] Clear GeGroups Error"
);
