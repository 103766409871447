import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";
import { ISearchResultsVm } from "@app/core/services/WebRegApiClient";

export const preloadSearchByTermCode = createAction(
  "[Search Actions] Load Search By Term Code",
  props<{ termCode: number }>()
);

export const preloadSearchByTermCodeFailure = createAction(
  "[Search Effect] Load Search By Term Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const preloadSearchByTermCodeSuccess = createAction(
  "[Search Actions] Search Successfully Loaded For Term",
  props<{ search: ISearchResultsVm, termCode: number }>()
);

export const clearError = createAction(
  "[Search Actions] Clear Search Error"
);
