import { ITermVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { TermActions } from "@modules/main/homepage/store/terms/terms-action-types";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface TermsState extends EntityState<ITermVM> {
  isSelectedTermLoaded: boolean;
  areActiveTermsLoaded: boolean;
  areAllTermsLoaded: boolean;
  areAllTermsLoading: boolean;
  areActiveTermsLoading: boolean;
  selectedTerm: ITermVM;
  selectedTermCode: string | undefined;
  errorModifying: ApiResponseErrorModel | string | undefined;
  errorLoading: ApiResponseErrorModel | string | undefined;
  isSaving: boolean;
  navSelectedTerm: ITermVM;
}

export function selectTermCode(a: ITermVM): number {
  return <number>a.termCode;
}

export const areActiveTermsLoaded = (state: TermsState) => state.areActiveTermsLoaded;
export const areAllTermsLoaded = (state: TermsState) => state.areAllTermsLoaded;
export const areActiveTermsLoading = (state: TermsState) => state.areActiveTermsLoading;
export const areAllTermsLoading = (state: TermsState) => state.areAllTermsLoading;
export const isSaving = (state: TermsState) => state.isSaving;
export const isSelectedTermLoaded = (state: TermsState) => state.isSelectedTermLoaded;

export const adapter = createEntityAdapter<ITermVM>(
  {
    selectId: selectTermCode
  }
);

export const initialTermsState = adapter.getInitialState({
  isSelectedTermLoaded: false,
  areActiveTermsLoaded: false,
  areAllTermsLoaded: false,
  areAllTermsLoading: false,
  areActiveTermsLoading: false,
  isSaving: false,
});

export const getSelectedTerm = (state: TermsState) => state.selectedTerm;
export const getNavSelectedTerm = (state: TermsState) => state.navSelectedTerm;

export const getErrorModifyingMessage = (state: TermsState) => state.errorModifying;
export const getErrorLoadingMessage = (state: TermsState) => state.errorLoading;

export const termsReducer = createReducer(
  initialTermsState,

  on(TermActions.updateSelectedTerm, (state, action) =>
    adapter.setOne(action.selectedTerm, {
      ...state,
      selectedTerm: action.selectedTerm,
      selectedTermCode: action.selectedTerm.termCode,
      selectedTermCodeLoaded: true
    })),

  on(TermActions.updateNavSelectedTerm, (state, action) =>
    adapter.setOne(action.navSelectedTerm, {
      ...state,
      navSelectedTerm: action.navSelectedTerm,
    })),

  on(TermActions.loadActive, (state, action) => {
      return {
        ...state,
        areActiveTermsLoading: true
      }
    }
  ),

  on(TermActions.loadActiveFailure, (state, action) => {
      return {
        ...state,
        errorLoading: action.error,
        areActiveTermsLoading: false
      }
    }
  ),

  on(TermActions.loadAll, (state, action) => {
      return {
        ...state,
        areAllTermsLoading: true,
        areActiveTermsLoading: true
      }
    }
  ),

  on(TermActions.loadAllFailure, (state, action) => {
      return {
        ...state,
        errorLoading: action.error,
        areAllTermsLoading: false
      }
    }
  ),

  on(TermActions.activeTermsInserted, (state, action) =>
    adapter.addMany(action.terms, {
      ...state,
      areActiveTermsLoaded: true,
      areActiveTermsLoading: false
    })),

  on(TermActions.allTermsInserted, (state, action) =>
    adapter.addMany(action.terms, {
      ...state,
      areAllTermsLoaded: true,
      areAllTermsLoading: false,
      areActiveTermsLoaded: true,
      areActiveTermsLoading: false
    })),

  on(TermActions.createTerm, (state, action) => {
    return {
      ...state,
      isSaving: true
    }
  }),

  on(TermActions.createSuccess, (state, action) =>
    adapter.setOne(action.term, {
      ...state,
      selectedTermCodeLoaded: false,
      isSaving: false
    })),

  on(TermActions.createFailure, (state, action) => {
      return {
        ...state,
        errorModifying: action.error,
        isSaving: false
      }
    }
  ),

  on(TermActions.deleteSuccess, (state, action) =>
    adapter.removeOne(action.term.termCode, {
      ...state
    })),

  on(TermActions.deleteFailure, (state, action) => {
      return {
        ...state,
        errorModifying: action.error
      }
    }
  ),

  on(TermActions.updateTerm, (state, action) => {
    return {
      ...state,
      isSaving: true
    }
  }),

  on(TermActions.updateSuccess, (state, action) =>
    adapter.setOne(action.term, {
      ...state,
      isSaving: false,
    })),

  on(TermActions.updateConfigurationSuccess, (state, action) =>
    adapter.setOne(action.term, {
      ...state,
      isSaving: false,
    })),

  on(TermActions.updateFailure, (state, action) => {
      return {
        ...state,
        errorModifying: action.error,
        isSaving: false
      }
    }
  ),

  on(TermActions.refreshSuccess, (state, action) =>
    adapter.setOne(action.term, {
      ...state
    })),

  on(TermActions.clearModifyingError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),

  on(TermActions.clearLoadingError, (state, action) => {
      return {
        ...state,
        errorLoading: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
